import { ResolveCart } from 'components/ResolveCart/ResolveCart'
import { VenueLanding } from 'components/Venue/VenueLanding'
import { VenuePage } from 'components/Venue/VenuePage'
import { VenuePageLoader } from 'components/Venue/VenuePageLoader'
import {
  OrderingTypeController,
  VenueController,
} from 'contexts/VenueOrderContext'
import useLogOnMount from 'hooks/useLogOnMount'
import {
  PartnerLoginContext,
  useLegacyLoginFallback,
  WithPartnerLogin,
} from 'hooks/usePartnerLogin'
import { Partner } from 'lib/gql'
import { CartDefaults } from 'lib/utils'
import { NextPage } from 'next'
import { NextUrqlPageContext } from 'next-urql'
import React from 'react'
import { getTableInfo } from 'utils/venue'

const MenuCategories: NextPage<CartDefaults> = ({ tableNumber, tableArea }) => {
  const { showLoader, legacyLogin } = useLegacyLoginFallback()
  useLogOnMount('View venue categories')

  return (
    <VenueController>
      <OrderingTypeController>
        <VenueLanding>
          {showLoader ? (
            <VenuePageLoader categoriesCount={6} />
          ) : (
            <WithPartnerLogin
              useLegacyLogin={legacyLogin}
              partner={Partner.Eonx}
            >
              <PartnerLoginContext.Consumer>
                {({ partnerResolved }) => (
                  <>
                    {!partnerResolved ? (
                      <VenuePageLoader categoriesCount={6} />
                    ) : (
                      <ResolveCart
                        tableArea={tableArea}
                        tableNumber={tableNumber}
                        loader={<VenuePageLoader categoriesCount={6} />}
                      >
                        <VenuePage orderingTypePrompt={false} />
                      </ResolveCart>
                    )}
                  </>
                )}
              </PartnerLoginContext.Consumer>
            </WithPartnerLogin>
          )}
        </VenueLanding>
      </OrderingTypeController>
    </VenueController>
  )
}

MenuCategories.getInitialProps = (ctx: NextUrqlPageContext) => {
  return {
    ...getTableInfo(ctx.query),
  }
}

export default MenuCategories
